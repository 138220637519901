import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { BoCommonFilters } from './bo-common-filters';
import { BoFilterByDate } from './bo-filter-by-date';

export class GestioneConditions extends BoCommonFilters {
    actions?: string[];
    date?: BoFilterByDate;
    orderBy?: string;
    constructor() {
        super();
        this.date = new BoFilterByDate();
    }
}

export interface GestioneFilters {
    conds: GestioneConditions;
    operativeMode: AptSalesProcess;
    macroOperativeMode?: string;
}
