import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { combineLatest, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { setContactBarVisibility } from '../../../store/actions/app.actions';
import { D365Service } from '../../services/d365/d365.service';
import { ModalComponent } from '../modal/modal.component';
import { ApttusService } from '../../services/apttus/apttus.service';
import { TranslateService } from '@ngx-translate/core';
import { take, map, mergeMap, catchError } from 'rxjs/operators';
import { selectHasContactBar } from '../../../store/selectors/app.selectors';
import { selectCartSegment, selectContactLead } from '../../../store/selectors/user.selectors';
import { EglState } from '../../../store/reducers';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { selectFlowType } from '../../../store/selectors/order-entry.selectors';
import { PrivateConfigurationService } from '../../services/shared/private-configuration.service';
import { FLOW_TYPE_LABELS_MAP } from '../../map/flow-type-labels-map';
import { FlowType } from '../../../store/models/flow-type';
import { FeatureToggleService } from '../../services/shared/feature-toggle.service';
import { CartService } from '@congacommerce/ecommerce';
import { EglCartExtended } from '../../models/apttus/tables/cart/egl-cart-extended';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';

@Component({
    selector: 'egl-contact-bar',
    templateUrl: './contact-bar.component.html',
    styleUrls: ['./contact-bar.component.scss'],
})
export class ContactBarComponent implements OnInit, OnDestroy {
    customerName: string;
    subscriptions: Subscription[] = [];
    leadId = '';
    @ViewChild('leadResponseModal', { static: true }) leadResponseModal: TemplateRef<ModalComponent>;
    modalRef: BsModalRef;

    modalDescription: string;
    modalTitle: string;
    iconColor: boolean;
    txtBtnSx: string;
    txtBtnDx: string;
    isVisible: boolean;
    leadFormGUID: string;
    isMicrobusiness: boolean;
    private flowType: FlowType;
    public flowTypeLabel: String;

    constructor(
        private store: Store<EglState>,
        private d365Service: D365Service,
        private apttusService: ApttusService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private configSrv: PrivateConfigurationService,
        private featureToggleSrv: FeatureToggleService,
        private cartSrv: CartService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    ngOnInit() {
        this.subscriptions = [
            combineLatest([
                this.store.select(selectFlowType).pipe(
                    map((flowType) => ({
                        flowType,
                        flowTypeKey: FLOW_TYPE_LABELS_MAP[flowType] || 'OPERATIVE_MODES.UNKNOWN',
                    })),
                    // Gestione etichetta VolturaCambioFornitore in base al featureToggle
                    map(({ flowType, flowTypeKey }) => ({
                        flowType,
                        flowTypeKey:
                            flowType === FlowType.VolturaCambioFornitore &&
                            !this.featureToggleSrv.isVolturaSwitchEnabled
                                ? 'OPERATIVE_MODES.SWITCH_IN_VOLTURA_OLD'
                                : flowTypeKey,
                    })),
                    mergeMap(({ flowType, flowTypeKey }) =>
                        this.translate.get(flowTypeKey).pipe(
                            map((flowTypeLabel) => (flowTypeLabel === flowTypeKey ? null : flowTypeLabel)),
                            catchError(() => of('')),
                            map((flowTypeLabel) => ({
                                flowType,
                                flowTypeLabel,
                            }))
                        )
                    )
                ),
                this.store.select(selectContactLead),
                this.cartSrv.getMyCart().pipe(map((cart: EglCartExtended) => cart)),
                this.store.select(selectCartSegment).pipe(map((segment) => segment)),
            ]).subscribe(([{ flowType, flowTypeLabel }, { customerInfo, lead, contact }, cart, segment]) => {
                this.flowType = flowType;
                this.flowTypeLabel = flowTypeLabel;
                this.isMicrobusiness = segment === D365CustomerSegment.Microbusiness;

                const cartIsEmpty = !cart.LineItems.find((x) => x.IsPrimaryLine);
                // isMB = (customerInfo?.Name || contact?.name || '').trim() altrimenti se è residenziale `${contact?.firstname || ''} ${contact?.lastname || ''}`.trim()
                this.customerName = (
                    this.isMicrobusiness
                        ? (customerInfo?.Name || contact?.name || '').trim()
                        : `${contact?.firstname || ''} ${contact?.lastname || ''}`.trim()
                ).toLowerCase();
                this.leadId = lead?.leadid || customerInfo?.LeadId;
                this.store.dispatch(
                    setContactBarVisibility({
                        v:
                            !!this.customerName ||
                            (flowType === FlowType.SwitchIn && !cartIsEmpty && flowTypeLabel) ||
                            (flowType !== FlowType.SwitchIn && flowTypeLabel),
                    })
                );
            }),
        ];
        this.setModalText();
        this.leadFormGUID = this.configSrv.config.d365.leadFormGUID;
    }

    private setModalText(): void {
        this.modalTitle = this.translate.instant('LEAD_RESPONSE.TITLE');
        this.modalDescription = this.translate.instant('LEAD_RESPONSE.MESSAGE');
        this.txtBtnSx = this.translate.instant('LEAD_RESPONSE.CANCEL');
        this.txtBtnDx = this.translate.instant('LEAD_RESPONSE.CONTINUE');
    }

    openModal(): void {
        if (this.flowType === FlowType.SwitchIn) {
            this.modalRef = this.modalService.show(this.leadResponseModal);
        }
    }

    async openLead(): Promise<void> {
        this.d365Service.openViewFormOnCrmWithParameters('lead', this.leadId, this.leadFormGUID, {
            egl_opencampaignresponseform: 'true',
        });
    }

    async esitaLead(): Promise<void> {
        this.apttusService.onCreateNewCartCompleted.pipe(take(1)).subscribe(async (activatedCartId: string) => {
            if (activatedCartId) {
                await this.openLead();
            }
        });
        this.apttusService.clearAndCreateNewCart(null);
    }

    public isBarVisible = this.store.select(selectHasContactBar);
}
