import { Address, MeterAppointment, OrderAppointment, PhoneContact } from '../../store/models/order-entry-state_v2';
import { getNumberOrNull } from './remap.functions';

export function getFullAddress(address: Partial<Address>, verbose?: boolean): string {
    if (!address?.street || !address?.municipality) {
        return '';
    }

    return [
        `${address.toponym || ''} ${address.street || ''}`,
        getCompleteCivic(address),
        address.cap,
        verbose && address.district ? `(${address.district}) ${address.municipality || ''}` : address.municipality,
        address.shortProvince,
    ]
        .map((value) => (value || '').trim())
        .filter(Boolean)
        .join(', ')
        .toUpperCase();
}

export const istatCodeNormalizer = (val: string | number, size: number = 3): string => {
    const intVal = typeof val === 'number' ? val : parseInt(val);
    return isNaN(intVal) || intVal === 0 ? '' : `${intVal}`.padStart(size, '0');
};

export const capNormalizer = (val: string | number): string => istatCodeNormalizer(val, 5);

export const getIstatCode = (address: Address): string =>
    address?.istatCodeProv && address?.istatCodeMunicipality
        ? `${address?.istatCodeProv}${address?.istatCodeMunicipality}`
        : null;

/**
 * @deprecated
 */
function checkIfExists(value: boolean): string {
    return value ? 'Si' : value === false ? 'No' : '';
}

export function createCSV(
    orderAppointment: OrderAppointment,
    meterAppointment: MeterAppointment,
    fullAddress: string,
    contact: PhoneContact
): string {
    return `${createMeterString(meterAppointment)}${createAppointmentString(contact)}${createInterPhoneString(
        orderAppointment,
        fullAddress
    )}${createAltContactString(orderAppointment)}${createNoteString(meterAppointment)}`;
}

function createMeterString(appointment: MeterAppointment): string {
    return `CONT:${getString(appointment?.location)};${checkIfExists(appointment?.available)};`;
}

function createAppointmentString(contact: PhoneContact): string {
    return `APP:${getString(contact?.prefix)}${getString(contact?.phone)};`;
}

function createInterPhoneString(appointment: OrderAppointment, fullAddress: string): string {
    return `CIT:${getString(fullAddress)};${checkIfExists(appointment?.interphone?.available)};${getString(
        appointment?.interphone?.notes
    )};`;
}

function createAltContactString(contact: OrderAppointment): string {
    return `RIF:${getString(contact?.firstName)};${getString(contact?.lastName)};${getString(
        contact?.prefix
    )}${getString(contact?.phone)};`;
}

function createNoteString(meterAppointment: MeterAppointment): string {
    return `NOTE:${getString(meterAppointment?.notes)};`;
}

export function addressToIstatCode(
    address: Pick<Address, 'istatCodeMunicipality' | 'istatCodeProv'>
): string | undefined {
    const istatCode = `${getString(address?.istatCodeProv).padStart(3, '0')}${getString(
        address?.istatCodeMunicipality
    ).padStart(3, '0')}`;
    return parseInt(istatCode) ? istatCode : undefined;
}

export function getString(value: string | number): string {
    return (value || '') + '';
}

export function getCivicSuffix(value: string): string {
    return (value || '').replace(/\d+/, '').trim() || null;
}

export function getCompleteCivic(address: { civic?: number | string; civicSuffix?: string }): string {
    if (!address) {
        return null;
    }
    const { civic, civicSuffix } = address || ({} as Address);
    return `${(civic || '') + ''} ${civicSuffix || ''}`.trim() || null;
}
export function getCivicZeroToSNC(address: Address | false): Address {
    if (!address) {
        return null;
    }
    let { civic, civicSuffix } = address || ({} as Address);
    if (civic === 0 && !civicSuffix) {
        address.civic = null;
        address.civicSuffix = 'SNC';
    }
    return address;
}

/**
 * Take data in input and format the string to show
 * @param zip: zip code
 * @param city: city
 * @param prov: province
 */
export function formatFullNameFromZIP(zip: string, city: string, prov: string) {
    const startString = [zip, (city || '').toUpperCase()].filter(Boolean).join(', ');
    return `${startString} ${prov ? prov.toUpperCase() : ''}`.trim();
}

export function formatPrice(price: number | string | null | undefined): string {
    // nel caso in cui il valore non è un numero valido il default è ''
    const parsedPrice = getNumberOrNull(price);
    return typeof parsedPrice === 'number' ? parsedPrice + '' : '';
}

/**
 * Esegue la sanificazione dei messaggi di log rimuovendo i caratteri CRLF
 * @param message
 * @returns Restitusice una stringa senza CRLF
 */
export function sanitizeLogMessage(message: string): string {
    return (typeof message === 'string' ? message : '')
        .replace(/(\r\n|\n|\r|\|%0a|%0d|%5c|%6e|%72|%4e|%52|%0A|%0D|%5C|%6E|%72|%4E|%52)/g, ' ')
        .replace(/<[^>]*>/g, '');
}
