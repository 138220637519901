import { Injectable } from '@angular/core';
import { ACondition, AObjectService, Operator } from '@congacommerce/core';
import {
    AssetLineItem,
    AssetLineItemExtended,
    Cart,
    CartItem,
    CartItemService,
    LineStatus,
    OrderLineItem,
    ProductAttributeValue,
    QuoteLineItem,
} from '@congacommerce/ecommerce';
import { of, throwError, zip } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { EglQuoteLightService } from '../tables/quote/egl-quote-light.service';
import { EglAssetLineItemService } from '../tables/asset/egl-assetline-item.service';
import { EglQuoteLineItemService } from '../tables/quote/egl-quote-line-item.service';
import { EglOrderLineItemService } from '../tables/order/egl-order-line-item.service';
import { EglCartItemExtended } from '../../../models/apttus/tables/cart/egl-cart-item-extended';
import { cloneDeep, concat } from 'lodash';
import { AptProductType } from '../../../enums/apttus/apt-product-type';

@Injectable({ providedIn: 'root', deps: [EglQuoteLightService] })
export class EglCartItemService extends CartItemService {
    getCartItemsForAsset(cartItem, context) {
        let _thisCartItem = cartItem;
        if (!cartItem)
            return throwError(
                new Error(
                    'You must provide a cart item with a related asset generated from the amend or configure asset API'
                )
            );
        return zip(
            this.getOptionsForItem(cartItem.AssetLineItem, null),
            this.getOptionsForItem(cartItem, context)
        ).pipe(
            map(([assetItems, cartItems]: [AssetLineItemExtended[], EglCartItemExtended[]]) => {
                // Map the asset lines
                if (_thisCartItem.LineStatus === LineStatus.Upgrade) {
                    return cartItems;
                } else {
                    assetItems = assetItems.filter((a) => a.AssetStatus !== LineStatus.Cancel);
                    return concat(
                        // To a new cart item
                        assetItems.map((asset) => {
                            const existing = cartItems.find((i) => i.AssetLineItemId === asset.Id);
                            if (existing) return existing;
                            else {
                                // Line copies the details of an asset line item to a new cart item ommiting the default Salesforce fields
                                const attributeValue = Object.assign(
                                    new ProductAttributeValue(),
                                    Object.fromEntries(
                                        Object.entries(asset.AttributeValue || {}).filter(
                                            (e) => !AObjectService.defaultFields.includes(e[0])
                                        )
                                    )
                                );

                                const newCartItem = Object.assign(
                                    new CartItem(),
                                    Object.fromEntries(
                                        Object.entries(asset || {}).filter(
                                            (e) => !AObjectService.defaultFields.includes(e[0])
                                        )
                                    )
                                );
                                newCartItem.AttributeValue = attributeValue;
                                newCartItem.AssetLineItem = cloneDeep(asset);
                                newCartItem.AssetLineItemId = asset?.Id;
                                newCartItem.LineStatus = LineStatus.Existing;
                                return newCartItem;
                            }
                        }),
                        cartItems.filter((i) => !i?.AssetLineItem),
                        cartItems.filter(
                            (i) => i?.LineStatus === LineStatus.Upgrade && i?.LineType !== 'Product/Service'
                        )
                    );
                }
            })
        );
    }

    getOptionsForItem(item: AssetLineItem | QuoteLineItem | OrderLineItem, relatedTo) {
        let service: EglAssetLineItemService | EglQuoteLineItemService | EglOrderLineItemService;
        if (item) {
            let field = 'ConfigurationId';
            const extraConditions = [];
            if (item instanceof AssetLineItem) {
                // BusinessObjectId è il QuoteId
                field = 'BusinessObjectId';
                service = this.injector.get(EglAssetLineItemService);

                // Bug 236904 - La query restituiva anche AssetLineItem con ProductType differenti, motivo per cui si è resa necessaria l'aggiunta del filtro (Es: veniva restituito anche GAS quando item era di tipo POWER)
                // Le commodity necessitano di raggruppamento in quanto c'è il rischio di non considerare tutti gli AssetLineItems collegati se si filtra per il puro ProductType
                const commodityProductTypes = [
                    [
                        AptProductType.FornituraGas,
                        AptProductType.ProdottoAtomicoCommodityGas,
                        AptProductType.ProdottoCommodityGas,
                        AptProductType.TariffaCommodityGas,
                    ],
                    [
                        AptProductType.FornituraLuce,
                        AptProductType.ProdottoAtomicoCommodityLuce,
                        AptProductType.ProdottoCommodityLuce,
                        AptProductType.TariffaCommodityLuce,
                    ],
                ];
                extraConditions.push(
                    new ACondition(
                        service.type,
                        'ProductType',
                        'In',
                        commodityProductTypes[0].includes(item.ProductType as AptProductType)
                            ? commodityProductTypes[0]
                            : commodityProductTypes[1].includes(item.ProductType as AptProductType)
                            ? commodityProductTypes[1]
                            : [item.ProductType]
                    )
                );
                // Bug 255768 - aggiunto il pod/pdr per prendere in considerazione le quote multifornitura (che quindi hanno stesso quoteId, stesso prodotto, stesso tipo di fornitura, ma podPdr diverso)
                if (!!(item as any).egl_POD_PDR) {
                    extraConditions.push(
                        new ACondition(service.type, 'egl_POD_PDR', Operator.EQUAL, (item as any).egl_POD_PDR)
                    );
                }
                // Bug 237864 - spostato il filtro nella query in quanto i LineStatus Cancelled e Superseded non vengono mai presi in considerazione
                // Superseded è scritto come stringa in quanto la libreria di Conga non lo ha in lista...
                // Ho dovuto scrivere due where perché l'api di Conga non sembra interpretare bene il "NotIn"...
                extraConditions.push(
                    new ACondition(service.type, 'AssetStatus', Operator.NOT_EQUAL, LineStatus.Cancel)
                );
                extraConditions.push(new ACondition(service.type, 'AssetStatus', Operator.NOT_EQUAL, 'Superseded'));
            } else if (item instanceof QuoteLineItem) {
                field = 'Proposal';
                service = this.injector.get(EglQuoteLineItemService);
            } else if (item instanceof OrderLineItem) {
                field = 'OrderId';
                service = this.injector.get(EglOrderLineItemService);
            }
            return relatedTo && item instanceof CartItem && relatedTo instanceof Cart
                ? of((relatedTo?.LineItems || []).filter((i) => i.LineNumber === item.LineNumber))
                : service.get([item.Id]).pipe(
                      map((res) => res[0]),
                      mergeMap((i) => {
                          if (i[field]) {
                              return service.where([
                                  new ACondition(service.type, 'LineNumber', 'Equal', i.LineNumber),
                                  new ACondition(service.type, field, 'Equal', i[field]),
                                  ...extraConditions,
                              ]);
                          } else {
                              return of([i]);
                          }
                      })
                  );
        } else return of(null);
    }
}
