import { BaseApexApiResponse } from '../../../../common/interfaces/base-apex-api-respose';

export interface ConventionListResponse extends BaseApexApiResponse {
    response: RawConvention[];
}

export class ConventionListRequest {
    Agency: string;
    SalesChannel: string;
    OperationType: string;
}

export interface RawConvention {
    Name: string;
    Apttus_Config2__IncentiveCode__c: string;
}
export interface Convention {
    code: string;
    name: string;
    productId?: string;
}
export class LocalStorageConventionsWrapper {
    cartId: string;
    conventions: Convention[];
    constructor() {
        this.conventions = [];
    }
}
