import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EglState } from '../../../store/reducers';
import { selectFlowType } from '../../../store/selectors/order-entry.selectors';
import { RoutesPaths } from '../../config/routes-paths';
import { IWyvernProgress } from '../../interfaces/wyvern-bar.interface';
import { ORDER_ENTRY_PATHS } from './router/dragon-router-config';
import { GLOBAL_SKIPPING_RULES } from './router/dragon-router-skipping-rules';
import { OrderEntryPathConfiguration, OrderEntrySubPathConfiguration } from './router/dragon-router.type';
import { DragonUtilsService } from './router/dragon-utils.service';

@Injectable({
    providedIn: 'root',
})
export class WyvernBarService {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private eglState: Store<EglState>,
        private dragonUtilsSrv: DragonUtilsService
    ) {}

    orderEntryEel(): Observable<IWyvernProgress> {
        return combineLatest([
            this.eglState.select(selectFlowType),
            this.router.events.pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event?.urlAfterRedirects.replace(/^(\/)/, ''))
            ),
            this.activatedRoute.queryParams,
        ]).pipe(
            map(([flowType, currentPath, params]) => ({ flowType, currentPath, params })),
            // Recuperiamo tutte le config che matchano il flowType
            map(({ flowType, ...data }) => ({
                ...data,
                configs: ORDER_ENTRY_PATHS.filter(({ flowTypes }) => flowTypes.includes(flowType)),
                flowType,
            })),
            // Rimappiamo i railway delle config e li concateniamo in un unico array
            map(({ configs, ...data }) => ({
                ...data,
                railway: configs.reduce(
                    (aggr, { railway }) => [...aggr, ...railway],
                    [] as OrderEntryPathConfiguration['railway']
                ),
            })),
            // Teniamo soltanto le pagine dell'order-entry
            map(({ railway, ...data }) => ({
                ...data,
                railway: railway
                    .filter(
                        (railwayStop) =>
                            this.isOrderEntryPath(railwayStop) ||
                            this.dragonUtilsSrv.isOrderEntrySubPathConfiguration(railwayStop)
                    )
                    .map((railwayStop) =>
                        this.dragonUtilsSrv.isOrderEntrySubPathConfiguration(railwayStop)
                            ? railwayStop?.railway.filter((subRailwayStop) => this.isOrderEntryPath(subRailwayStop))
                            : railwayStop
                    ) as (string | (OrderEntrySubPathConfiguration & { railway: string[] }))[],
            })),
            // Rimuove i duplicati
            map(({ railway, ...data }) => ({
                ...data,
                railway: Array.from(new Set(railway)).map((railwayStop, ri, railwayList) =>
                    this.dragonUtilsSrv.isOrderEntrySubPathConfiguration(railwayStop)
                        ? Array.from(new Set(railwayStop.railway)).filter(
                              (subRailwayStop) => !railwayList.includes(subRailwayStop)
                          )
                        : railwayStop
                ),
            })),
            // Filtrare in base alle skippingRules
            mergeMap(({ railway, params, ...data }) =>
                combineLatest(
                    railway.map((railwayStop) =>
                        Array.isArray(railwayStop)
                            ? combineLatest(
                                  railwayStop.map((subRailwayStop: string) =>
                                      this.dragonUtilsSrv
                                          .railwaySkipAdapter(GLOBAL_SKIPPING_RULES[subRailwayStop]?.skip)(params)
                                          .pipe(map((skip) => (skip ? null : subRailwayStop)))
                                  )
                              ) //.pipe(map((allowedRailwayStops) => allowedRailwayStops.filter(Boolean)))
                            : this.dragonUtilsSrv
                                  .railwaySkipAdapter(GLOBAL_SKIPPING_RULES[railwayStop]?.skip)(params)
                                  .pipe(map((skip) => (skip ? null : railwayStop)))
                    )
                ).pipe(
                    //se la sottorotta salta l'ultima pagina, che serve a gestire il loop, appiattisco la lista poichè c'è solo un'iterazione della sottorotta
                    map((allowedRailwayStops) =>
                        allowedRailwayStops.reduce((aggr, railwayStop) => {
                            // Gestione sottorotte
                            if (Array.isArray(railwayStop)) {
                                // Filtro le sottorotte
                                const allowedSubRailwayStops = railwayStop.filter(Boolean);
                                // Verifico se l'ultima pagina della sottorotta, necessaria per il loop, deve essere saltata (skip)
                                return !railwayStop[railwayStop.length - 1]
                                    // Se l'ultima pagina deve essere saltata, la sottorotta va eseguita una volta sola e quindi appiattisco la sottorotta unendola alla rotta principale
                                    ? [...aggr, ...allowedSubRailwayStops]
                                    // Se l'ultima pagina deve essere visitata, e la sottorotta quindi ripetuta, mantengo la sottorotta ma rimuovo l'ultima pagina
                                    : [...aggr, allowedSubRailwayStops.slice(0, -1)];
                            }
                            // Gestione rotte
                            // Filtriamo l'elenco di fermate e di sottofermate in base agli esiti delle skipping rules
                            return !!railwayStop ? [...aggr, railwayStop] : aggr;
                        }, [] as (string | string[])[])
                    ),
                    map((railway) => ({ ...data, params, railway }))
                )
            ),
            // Predispongo due array, uno con la lista delle fermate principali l'altro con la lista delle sottofermate se mi trovo al suo interno
            map(({ railway, currentPath, ...data }) => ({
                ...data,
                currentPath,
                railway: railway.filter(
                    (railwayStop) =>
                        this.isRootRailwayStop(railwayStop) || this.isSubRailwayStops(railwayStop, currentPath)
                ),
                rootRailway: railway.filter(this.isRootRailwayStop),
                subRailway: railway.find((railwayStop): railwayStop is string[] =>
                    this.isSubRailwayStops(railwayStop, currentPath)
                ),
            })),

            // Determinare totale pagine & posizione della pagina corrente
            map(({ railway, rootRailway, subRailway, currentPath }) => ({
                position:
                    (subRailway?.length
                        ? rootRailway.indexOf(railway[railway.indexOf(subRailway) - 1] as string)
                        : this.dragonUtilsSrv.findRailwayStopIndex(
                              rootRailway.map((path) => ({ path, component: null })),
                              currentPath
                          )) + 1, //incremento di 1 per la numerazione umana 1..n
                total: rootRailway?.length,
                subPosition: subRailway?.length
                    ? this.dragonUtilsSrv.findRailwayStopIndex(
                          subRailway.map((path) => ({ path, component: null })),
                          currentPath
                      ) + 1 //incremento di 1 per la numerazione umana 1..n
                    : 0,
                subTotal: subRailway?.length || 0,
                currentRoutePath:
                    this.dragonUtilsSrv.findRailwayStop(rootRailway || [], currentPath) ||
                    this.dragonUtilsSrv.findRailwayStop(subRailway || [], currentPath),
                journey: railway
                    .reduce<string[]>((aggr: string[], railwayStop) => aggr.concat(railwayStop), [])
                    .map((routePath) => ({
                        routePath,
                        descriptionId: this.getDescriptionId(routePath),
                    })),
            })),
            map(({ currentRoutePath, ...data }) => ({
                ...data,
                descriptionId: this.getDescriptionId(currentRoutePath),
            }))
        );
    }

    private isOrderEntryPath(path: any): path is string {
        return (
            typeof path === 'string' &&
            this.dragonUtilsSrv.ORDER_ENTRY_ROOTS.some((oeRoot) => path.startsWith(oeRoot.replace(/\/$/, '') + '/'))
        );
    }

    private isRootRailwayStop(railwayStop: string | string[]): railwayStop is string {
        return typeof railwayStop === 'string';
    }

    private isSubRailwayStops(railwayStop: string | string[], currentPath: string): railwayStop is string[] {
        return (
            Array.isArray(railwayStop) &&
            railwayStop.some(
                (subRailwayStop, idx, subRailway) =>
                    idx < subRailway.length - 1 && this.dragonUtilsSrv.checkCurrentRoute(subRailwayStop, currentPath)
            )
        );
    }

    private getDescriptionId(routePath): string | null {
        return (
            ((Object.entries(RoutesPaths).find(([, path]) => path === routePath) || [])[0] || '').toUpperCase() || null
        );
    }
}
